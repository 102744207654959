<template>
  <div class="login feRegister">
    <div class="login-title">
       <p>
        <img :src="titleLogo?.pcLogo || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/logo1.png'" v-if="titleLogo.showLogo"/>
        <span class="name" v-if="titleLogo.showTitle">{{titleLogo?.title || '安全与应急管理培训服务平台'}}</span>
      </p>
    </div>
    <div class="login-form">
      <div class="content-box">
        <!-- <div class="login-left-img">
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/login/login-left.png" alt="">
        </div> -->
        <div class="login-right-form">
          <fe-login-register :tabsList="tabsList" :activeName="activeName" @change="setActiveName"></fe-login-register>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feLoginRegister from './../components/fe-login-register'
import isOfficial from '@/utils/common'
import { getConfigureValue } from '@/utils/configure.js'

export default {
  data() {
    return {
      activeName:'',
      titleLogo:null,//标题和logo
    }
  },
  components:{
    feLoginRegister
  },
  created() {
    this.titleLogo = getConfigureValue('yj_ssbase_configure') && JSON.parse(getConfigureValue('yj_ssbase_configure'));
    if(isOfficial){
      this.tabsList = [
        { key: "govt", val: "我是政府/协会管理者" },
        { key: "ldt", val: "我是企业管理者" },
        { key: "user", val: "我是学员" },
      ]
      this.activeName = 'govt'
    }else{
      this.tabsList = [
        { key: "user", val: "我是学员" },
      ]
      this.activeName = 'user';
    }
  },
  methods: {
    setActiveName(val) {
        if (val === this.activeName) return
        this.activeName = val;
    },
  },
}
</script>

<style lang="scss" src="../asset/css/login.scss" scoped>
</style>
<style lang="scss" scoped>
.feRegister{
  .login-form{
    .login-right-form{
      width:655px;
      padding-top:65px;
    }
  }
}
</style>
