<template>
  <div class="login" :class="currentType">
    <div class="login-title">
       <p>
        <img :src="titleLogo?.pcLogo || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/logo1.png'" v-if="titleLogo.showLogo"/>
        <span class="name" v-if="titleLogo.showTitle">{{titleLogo?.title || '安全与应急管理培训服务平台'}}</span>
      </p>
    </div>
    <div class="login-form">
      <div class="content-box">
        <!-- <div class="login-left-img">
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/login/login-left.png" alt="">
        </div> -->
        <div class="login-right-form">
          <h2>{{type[currentType]}}注册</h2>
          <government v-if="currentType=='govt'"/>
          <enterprise v-if="currentType=='ldt'"/>
          <user v-if="currentType=='user'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from './../components/user.vue'
import enterprise from './../components/enterprise.vue'
import government from './../components/government.vue'
import { getConfigureValue } from '@/utils/configure.js'

export default {
  data() {
    return {
      titleLogo:null,//标题和logo
      currentType:'govt',
      type:{
        'govt':'政府/协会',
        'ldt':'企业',
        'user':'学员'
      },
    }
  },
  components:{
    user,
    enterprise,
    government
  },
  created() {
    this.titleLogo = getConfigureValue('yj_ssbase_configure') && JSON.parse(getConfigureValue('yj_ssbase_configure'));
    this.currentType = this.$route.query.type || this.$route.query.from || 'govt';
  },
  methods: {
  }
}
</script>

<style lang="scss" src="../asset/css/login.scss" scoped>
</style>