<template>
  <div class="login">
    <div class="login-title">
      <p>
        <img :src="titleLogo?.pcLogo || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/logo1.png'" v-if="titleLogo.showLogo"/>
        <span class="name" v-if="titleLogo.showTitle">{{titleLogo?.title || '安全与应急管理培训服务平台'}}</span>
      </p>
    </div>
    <div class="login-form">
      <div class="content-box">
        <!-- <div class="login-left-img">
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/login/login-left.png" alt="">
        </div> -->
        <div class="login-right-form">
          <h2>{{type[currentType]}}登录</h2>
          <ul class="tabs">
            <li :key="item.key" v-for="(item) in tabsList" @click="setActiveName(item.key)" class="tabItem"
              :class="{ active: currentTab === item.key }">{{ item.val }}</li>
          </ul>
          <el-form class="form" ref="form" :rules="rules" :model="formData">
            <el-form-item prop="account" ref="account">
              <el-input placeholder="请输入手机号" autofocus v-model="formData.account" @keyup.enter.native="onSubmit" key="account"
                clearable></el-input>
            </el-form-item>
            <el-form-item prop="password" v-if="currentTab === 'password'" class="passwordItem" ref="password">
              <el-input :type="pass" placeholder="请输入密码" v-model="formData.password" key="password" clearable>
                <template slot="append">
                  <span @click="handleshowPsd()" class="pwd-icon">
                    <svg-icon icon-class="yanjing-2" v-if="pass == 'text'"></svg-icon>
                    <svg-icon v-else icon-class="yanjing-1"></svg-icon>
                  </span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item v-else prop="authCode" class="authCode" ref="authCode">
              <el-input placeholder="请输入验证码" v-model="formData.authCode" @keyup.enter.native="onSubmit">
                <el-button slot="append" @click="getAuthCode" round>{{ 0 >= Countdown ? '获取验证码' : Countdown + 's'
                }}</el-button>
              </el-input>

            </el-form-item>
          </el-form>
          <div class="bottom">
            <div class="read">
              <el-checkbox v-model="hasreaded">
                <span>我同意</span>
                <span class="link" @click.stop="goPage(1)">《用户协议》</span>
                <span>和</span>
                <span class="link"  @click.stop="goPage(2)">《法律声明及隐私政策》</span>
              </el-checkbox>
            </div>
            <div class="loginBottom">
              <p class="loginBtn" @click="onSubmit">登录</p>
              <!-- <p  v-if="activeName === 'password'" class="forget"><router-link to="/forget">忘记密码？</router-link></p> -->
              <div class="to-register">
                <p>没有账号？<span @click="toRegister">立即注册 ></span></p>
                <p v-if="currentType=='user' && currentTab=='Verification'" class="tipwe">未注册手机号验证后自动创建账号</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import { phoneValidator, isPhone } from '@/utils/validator'
import URL from "../../../config/url";
import {jumpRegister} from '@/utils/getBranchDomain.js'
import { getConfigureValue } from '@/utils/configure.js'
import isOfficial from '@/utils/common'

export default {
  data() {
    return {
      titleLogo:null,//标题和logo
      privacyURL:'https://wd-static-page.wdeduc.com/wd-yi-agreement/privacy.html',
      agreementURL:'https://wd-static-page.wdeduc.com/wd-yi-agreement/service-agreement.html',
      pass: 'password',
      hasreaded: false,
      CountdownInterval: null,
      Countdown: 0,
      currentTab: '',
      tabsList: [],
      currentType:'user',
      type:{
        'govt':'政府/协会',
        'ldt':'企业',
        'user':'学员'
      },
      formData: {
        account: null,
        password: null,
        authCode: null
      },
      wdObj:getConfigureValue('yjgw_wd_base') && JSON.parse(getConfigureValue('yjgw_wd_base')),
      rules: {
        account: [
          {
            validator: phoneValidator,
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
    }
  },
  computed: {
  },
  created(){
    this.titleLogo = getConfigureValue('yj_ssbase_configure') && JSON.parse(getConfigureValue('yj_ssbase_configure'));
    this.currentType = this.$route.query.from || 'user';
    this.formData.account = this.$route.query.account || '';
    if(this.currentType == 'user'){
      this.tabsList = [
        { key: "Verification", val: "手机号登录" },
        { key: "password", val: "密码登录" }
      ]
    }else{
      this.tabsList = [
        { key: "password", val: "密码登录" },
        { key: "Verification", val: "验证码登录" }
      ]
    }
    this.currentTab = this.tabsList[0].key;
    this.getConfiguration()
    sessionStorage.removeItem('index')
  },
  methods: {
    //   隐私协议跳转
    goPage(type){
        let URL = type === 1 ? this.agreementURL  : this.privacyURL;
        window.open(URL)
    },
    handleshowPsd() {
      if (this.pass == 'password') {
        this.pass = 'text'
      } else {
        this.pass = 'password'
      }
    },
    // tab 切换
    setActiveName(val) {
      if (val === this.currentTab) return
      this.currentTab = val;
      this.$refs.account.clearValidate();
      if (this.currentTab === "password") {
        this.formData.authCode = '';
        this.$refs.authCode.clearValidate();
      } else {
        this.formData.password = '';
        this.$refs.password.clearValidate();
      }
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    // 获取验证码
    async getAuthCode() {
      const data = { account: this.formData.account, type: 1 };
      this.formData.authCode = null;
      if (!this.formData.account) {
        this.$message.warning('请填写手机号');
        return false;
      }
      if (!isPhone.test(this.formData.account)) {
        this.$message.warning('请填写正确的手机号');
        return false;
      }
      if (this.Countdown > 0) return;
      this.Countdown = 60;
      this.setCountdown();
      this.$sensors.track('UM_Click_Verification_Code_Button')
      this.$api.usercenter.postAccountGetAuthCode({ data })
    },
    // 登录
    async onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.hasreaded) {
            this.$message.warning('请先阅读并同意协议')
            return false;
          }
          this.toLogin();
        } else {
          return false;
        }
      });
    },
    async getUserBranchList() {
      const res = await this.$api.usercenter.getFindUserBranchList({});
      if (res.data && res.data.length) {
        localStorage.setItem('UserBranchList', JSON.stringify(res.data))
        localStorage.setItem('branchUcode',res.data[0].ucode)
        await this.switchUserBranch(res.data[0].ucode)
      }
      if(res.code==40000){
        localStorage.removeItem('token');
      }
    },
    goAdmin(token){
      window.open(
        URL.env
          ? `https://emergency-admin.${URL.env}.wdeduc.com?token=${token}`
          : `https://emergency-admin.wdeduc.com?token=${token}`
      ,'_self');
    },
    async switchUserBranch(ucode) {
      const params = {
        ucode
      }
      const res = await this.$api.usercenter.switchUserBranch({ params });
      if (res.success) {
        await this.$store.dispatch("getUserInfo");
        await this.$store.dispatch("getUserInfoSecurity");
        this.$api.plan.expiringCertificate({
          pageSize:10,
          pageNum:1
        }).then(res=>{
          if(res.code==0 && res.data?.list?.length>0){
            let str ='你的';
            res.data.list.map((item,index)=>{
              const length = res.data.list.length;
              str = str + `《${item.certificateName}》将于${this.$dayjs(item.expireAt).format("YYYY.MM.DD")}过期${(length>1 && index<length-1)?',':''}`
            })
            this.$message({
              duration: 5000,
              type: "warning",
              message:str,
          });
          }
        })
        if (this.$route.query.name) {
          this.$router.push({
              path: this.$route.query.name + window.location.search
          })
        }else{
          this.$router.push('/')
        }
      }
    },
    async getConfiguration() {
      const res = await this.$store.dispatch('getConfiguration');
      if (res.websiteSetting && res.websiteSetting.pageLogo) {
        let link = document.querySelector("link[rel*='icon']");
        link.href = res.websiteSetting.pageLogo;
        localStorage.setItem('pageLogo', res.pageLogo);
      }
      if (res.websiteSetting && res.websiteSetting.pageTitle) {
        document.title = res.websiteSetting.pageTitle;
        localStorage.setItem('pageTitle', res.pageTitle)
      }
    },
    async toLogin() {

      const data = {
         ...this.formData,
        adminFlag:this.currentType!='user'?1:undefined,
        branchUcode:(this.currentType=='user' && isOfficial)?this.wdObj?.ucode:undefined,
      };
      data.password && (data.password = md5(data.password).toLowerCase())
      let method = ''
      if (this.currentTab === 'password') {//密码登录
        method = 'postAccountLogin'
      } else {//验证码登录
        method = 'postAccountLoginByAuthCode'
      }
      await this.$api.usercenter[method]({ data })
        .then(async res => {
          if (res.data) {
            localStorage.setItem("token", res.data);
            localStorage.setItem('identity',this.currentType);
            try {
              this.getConfiguration();
              if(this.currentType!='user'){
                this.goAdmin(localStorage.getItem('token'))
                return
              }
              await this.getUserBranchList()
            } catch (error) {
              console.log(error, 'error----')
              if (error) {
                this.$message.error(error)
                return
              }
            }
          } else {
            return res.data;
          }
        })
        .catch(() => {
          return false;
        });
    },
    toRegister(){
      jumpRegister(this.$route.query.type || this.$route.query.from || 'user')
    },
  }
}
</script>

<style lang="scss" src="../asset/css/login.scss" scoped>
</style>