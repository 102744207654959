<template>
  <div class="login">
    <div class="content-box">
      <div class="login-left-img">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/login/left-img1.png" alt="">
        <img class="left-logo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/login/left-logo.png" alt="">
      </div>
       <div class="login-right-form">
      <div class="forget-box">
        <div class="progress">
          <span class="line1 line" :class="{active:step >= 2}"></span>
          <span class="line2 line" :class="{active:step == 3}"></span>
          <div class="outer-1 outer" :class="{active:step >= 1}">
            <div class="inner">1</div>
            <span class="txt">身份验证</span>
          </div>
          <div class="outer-2 outer" :class="{active:step >= 2}">
            <div class="inner">2</div>
            <span class="txt">设置新密码</span>
          </div>
          <div class="outer-3 outer" :class="{active:step == 3}">
            <div class="inner">3</div>
            <span class="txt">重置成功</span>
          </div>
        </div>
        <div class="form">
          <template v-if="step == 1">
            <el-form ref="form" :rules="rules" :model="formData">
              <el-form-item prop="account" ref="account"> 
                <el-input
                  placeholder="请输入手机号/邮箱账号"
                  v-model="formData.account"
                  @keyup.enter.native="onSubmit"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="authCode" ref="authCode" class="authCode"> 
                <el-input
                  placeholder="请输入验证码"
                  v-model="formData.authCode"
                  @keyup.enter.native="onSubmit"
                >
                  <el-button slot="append" @click="getAuthCode"  round
                    >{{ 0 >= Countdown ? '发送验证码' : Countdown + 's' }}
                  </el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </template>
          <template v-else-if="step == 2">
            <el-form ref="form1" :rules="rules1" :model="formData1">
              <el-form-item prop="password" key="password">
                <el-input
                  @blur="comparePassword"
                  placeholder="请设置8-20位，数字、大小写字母、字符组合密码"
                  :type="passw"
                  v-model="formData1.password"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="rePassword" key="rePassword">
                <el-input
                  @blur="comparePassword"
                  placeholder="请再次输入登录密码"
                  :type="passw"
                  v-model="formData1.rePassword"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-form>
          </template>
          <template v-else>
            <div class="success">
              <div class="success-txt">恭喜您，密码重置成功！</div>
              <div class="tip">请妥善保管您的账户信息</div>
            </div>
          </template>
        </div>
        <div class="btn">
          <el-button
            class="forgetbtn"
            round
            size="mini"
            type="primary"
            @click="nextStep(step)"
            >{{step == 1? '下一步':step == 2?'确认重置':step == 3? '去登录':''}}
          </el-button>
          <div class="forgetpsd" v-if="step != 3">
            <a  @click="$router.go(-1)" style="cursor:pointer">
              返回登录 >
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { isPhone, isEmail, passwordValidator } from '@/utils/validator'
import { getConfigureValue } from '@/utils/configure.js'

export default {
  name: "forget",
  data() {
    const accountValidator = (rule, value, callback) => {
      if (isPhone.test(value) || isEmail.test(value) ){
        callback();
      } else {
        callback(new Error("请输入正确的手机号/邮箱账号"));
      }
    }
    return {
      titleLogo:null,//标题和logo
      step:1,
      Countdown:0,
      passw: "password",
      rules: {
        account: [
          {
            required: true,
            message: "请输入手机号/邮箱账号",
            trigger: "blur"
          },
          {
            validator: accountValidator,
            trigger: "blur"
          }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
      rules1: {
        password: [
          { required: true, message: "登录密码不能为空", trigger: "blur" },
          { validator: passwordValidator, trigger: 'blur' }
        ],
        rePassword: [{ required: true, message: "登录密码不能为空", trigger: "blur" }]
      },
      formData: {
        account: null,
        authCode: null
      },
      formData1: {
        password:null,
        rePassword:null,
      }
    };
  },
  created () {
    this.titleLogo = getConfigureValue('yj_ssbase_configure') && JSON.parse(getConfigureValue('yj_ssbase_configure'));
  },
  mounted() {
    
  },
  methods: {
    async nextStep(step) {
      if(step === 1){
        this.$refs.form.validate(valid => {
          if (valid) {
            this.checkAuthCode()
          } else {
            return false;
          }
        });
      }else if(step === 2){
        if(this.comparePassword()){
          this.resetPassword()
        }
      }else{
        this.$router.replace('/login')
      }
    },
    checkAuthCode(){
      let result = false
      let data = {...this.formData}
      this.$api.usercenter.checkAuthCode({data}).then(res => {
        result = res.data
        if(result){
          this.step = 2
        }
      });
    },
    resetPassword(){
      let result = false
      let data = {...this.formData,password:this.formData1.password}
      this.$api.usercenter.resetPassword({ data }).then(res => {
        result = res.data
        if(result){
          this.step = 3
        }
      });
    },
    // 获取验证码
    async getAuthCode() {
      const data = { account: this.formData.account, type: 1 };
      this.formData.authCode = null;
      if (!this.formData.account) {
        this.$message.warning('请填写手机号');
        return false;
      }
      if (!isPhone.test(this.formData.account)) {
        this.$message.warning('请填写正确的手机号');
        return false;
      }
      if (this.Countdown > 0) return;
      this.Countdown = 60;
      this.setCountdown();
      this.$api.usercenter.postAccountGetAuthCode({ data })
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    comparePassword(){
      let result = true
      if(this.formData1.password && this.formData1.rePassword){
        if(this.formData1.password !== this.formData1.rePassword){
          this.$message.warning('两次密码输入不一致')
          result = false
        }
      }
      return result
    }
  }
};
</script>
<style lang="scss"  scoped>
@import './../asset/css/login.scss';
@import './../asset/css/forget.scss';
</style>
 